@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #4B01E0;
  --primary-light: #f6f1ff;
  --secondary: #8E2DE2;
  --dim-green: #C9F9C9;
  --dim-secondary: #FEEAB9;
  --dim-primary: #C7C4F7;
  --dim-primary-lighter: #DFDDFF;
  --light-gray: #858585;
  --deep-blue: #1C00C5;
  --light-yellow: #FFEFB7;

  --stone-50: #FAFAF9;
  --stone-300: #D6D3D1;
  --stone-600: #57534E;
  --stone-800: #292524;

  --primary-gradient: linear-gradient(0deg, #4A00E0 0%, #8E2DE2 105%);
}
