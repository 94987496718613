@tailwind base;
@tailwind components;
@tailwind utilities;

.page-title {
  @apply font-poppins text-2xl font-bold leading-9 tracking-tight text-gray-900;
}

.form-field {
  @apply w-full flex flex-col my-4;
}

.form-label {
  @apply font-semibold text-sm text-black pb-1 ml-1.5;
}

.form-fieldset-label {
  @apply form-label;
  @apply text-base font-bold ml-0;
}

.form-input {
  @apply block w-full rounded-md border-0 py-2 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
}

.form-input-error {
  @apply text-red-500 text-sm;
}

.form-input-error:first-letter {
  text-transform: uppercase;
}

.dashboard-title {
  @apply page-title;
  color: var(--stone-800);
}

.talk-bubble {
  @apply relative bg-white py-3 px-4 rounded-lg text-black border border-white;
  font-size: 15px;
}

.talk-bubble-danger {
  @apply relative bg-red-100 py-3 px-4 rounded-lg text-black border border-red-100;
  font-size: 15px;
}

.active-background {
  @apply cursor-pointer text-white;
  background: var(--primary-gradient);
  border: none;
}
